import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import browseImg from '../../Assets/images/invoice-settings/default-logo.png';
import '../../Assets/css/formError.css';
import AxiosInstance from '../../Utility/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ValidationHelper from '../../Utility/helpers/validation.helper';
import { toast } from 'react-toastify';

const AddCompany = (props) => {
  const [Logo, setLogo] = useState(null);
  const [LogoPrev, setLogoPrev] = useState(null);
  const [message, setMessage] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [FormDatas, setFormData] = useState({});

  const createCompany = (e) => {
    e.preventDefault();
    // setDisabled(true);
    // if (Logo === null) {
    //   setFormData({ ...FormDatas });
    // } else {
    //   setFormData({ ...FormDatas, logo: Logo });
    // }

    if (Object.keys(FormDatas).length === 0) {
      setDisabled(false);
      setMessage({
        name: ['This field is required.'],
      });
      return true;
    }

    let newFormData = new FormData();
    // if (FormDatas.logo) {
    if (Logo !== null) {
      newFormData.append('logo', Logo);
    }
    // }
    newFormData.append('name', FormDatas.name);

    AxiosInstance.post('/companies/', newFormData)
      .then((response) => {
        setAddComp(false);
        props.getCompanies();
        setFormData({});
        setLogoPrev(null);
        setMessage({});
        toast.success('Company created successfully!');
      })
      .catch((err) => {
        if (err.response) {
          setMessage(err.response.data);
          toast.error('Error creating company!');
        }
      });
  };

  const onChangeHandler = (e) => {
    let { name, value } = e.target;

    if (name === 'name') {
      let checkComapany = ValidationHelper.CompanyNameValidation(value);
      if (!checkComapany) {
        setMessage({
          ...message,
          name: ['Company name must be valid!'],
        });
      } else {
        let new_message = message;
        delete new_message.company;
        setMessage(new_message);
      }

      setFormData({ ...FormDatas, [name]: value });
    }
  };

  const onChangeLogoHandler = (e) => {
    setLogoPrev(URL.createObjectURL(e.target.files[0]));
    setLogo(e.target.files[0]);
  };

  const onClickHandler = () => {
    setMessage({});
    setAddComp(false);
    setLogo('');
    setLogoPrev(null);
  };

  const { addComp, setAddComp } = props;

  return (
    <Modal
      show={addComp}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='add-company-modal'
    >
      <Modal.Header>
        <h3 className='text-3 text-semibold my-auto'>
          New <span className='text-primary'>Company</span>
        </h3>
        <button
          onClick={onClickHandler}
          type='button'
          className=' btn border-0 fw-bold text-muted p-0'
          aria-label='Close'
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal.Header>

      <Modal.Body>
        <form
          className='form-validate add-company'
          noValidate='noValidate'
          onSubmit={createCompany}
        >
          <div className='form-group form-group--file-upload'>
            <label className='form-label'>Company Logo</label>

            {LogoPrev === null ? (
              <>
                <div className='form-control-wrap d-flex align-items-center'>
                  <label htmlFor='comp-logo'>
                    <div className='image-preview'>
                      <figure className='h-100'>
                        <img
                          src={browseImg}
                          alt=''
                          width='50'
                          className='h-100'
                        />
                      </figure>
                    </div>
                  </label>
                  <div className='file-upload ml-2'>
                    <p className='text-8 text-regular mb-0 uploadfile'>
                      <span>Upload an image.</span>

                      <label
                        htmlFor='comp-logo'
                        className='file-upload-label mb-0'
                      >
                        {' '}
                        <span className='text-info p-1'> Browse</span>
                      </label>
                    </p>

                    <input
                      type='file'
                      className='file-upload-input'
                      id='comp-logo'
                      accept='.png,.jpg'
                      onChange={onChangeLogoHandler}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='form-control-wrap d-flex align-items-center'>
                  <div className='image-preview'>
                    <figure className='h-100'>
                      <img src={LogoPrev} alt='' width='50' className='h-100' />
                    </figure>
                  </div>
                  <div className='file-upload'>
                    <label
                      htmlFor='comp-logo'
                      className='file-upload-label mb-0'
                    >
                      <p className='text-9 text-regular mb-0 uploadfile'>
                        {Logo.name}
                        {/* <span className='text-info'>Browse</span> */}
                      </p>
                    </label>

                    <input
                      type='file'
                      className='file-upload-input'
                      id='comp-logo'
                      accept='.png,.jpg'
                      onChange={onChangeLogoHandler}
                    />
                  </div>
                </div>
              </>
            )}
            <div>
              <p className='input-error'> {message.logo}</p>
            </div>
            <div className='form-group pt-4'>
              <label htmlFor='name'>Company Name</label>
              <input
                type='text'
                className={classNames('form-control', {
                  'is-invalid': message.name,
                })}
                placeholder='Enter Company Name'
                name='name'
                id='name'
                onChange={onChangeHandler}
              />
              {message.name ? (
                <div className='invalid-feedback'>{message.name.join('')}</div>
              ) : null}
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onClickHandler}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={disabled}
            >
              Add Company
            </button>
          </div>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
    </Modal>
  );
};

export default AddCompany;
