class NameSplitHelper {
  static SidebarCompanyName = (name) => {
    let splitName = name.split(' ');
    if (splitName.length > 2) {
      if (
        splitName[0].length + splitName[1].length + splitName[2].length >
        15
      ) {
        if (splitName[0].length + splitName[1].length >= 15) {
          return [splitName[0]];
        } else {
          return [splitName[0], ' ', splitName[1]];
        }
      } else {
        return [splitName[0], ' ', splitName[1], ' ', splitName[2]];
      }
    } else if (splitName.length > 1) {
      if (splitName[0].length + splitName[1].length >= 15) {
        return [splitName[0]];
      } else {
        return [splitName[0], ' ', splitName[1]];
      }
    } else {
      return name;
    }
  };
}

export default NameSplitHelper;
