import React, { useCallback, useEffect, useState } from 'react';
import Header from '../Components/header/Header.component';
import SideNav from '../Components/sidebar/SideNav.component';
import SubSideNav from '../Components/sidebar/SideNavSub.componnet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MemberGoalBar from '../Components/member/memberGoalBar.component';
import AxiosInstance from '../Utility/axios';

const SettingsLayout = (props) => {
  // render() {
  const [SidebarToggle, setSidebarToggle] = useState(true);
  const { children } = props;

  const [memberGoalData, setMemberGoalData] = useState({});

  const memberGoal = () => {
    if (props.userDetails.role === 'member') {
      AxiosInstance.get(
        `/company/${props.match.params.company_id}/members/invoice_goal`
      ).then((response) => {
        if (response.status === 200) {
          setMemberGoalData(response.data);
        }
      });
    }
  };

  const fetchMemberGoalInfo = useCallback(() => {
    if (props.userDetails.role === 'member') {
      AxiosInstance.get(
        `/company/${props.match.params.company_id}/members/invoice_goal`
      ).then((response) => {
        if (response.status === 200) {
          setMemberGoalData(response.data);
        }
      });
    }
  }, [props.match.params.company_id, props.userDetails.role]);

  useEffect(() => {
    fetchMemberGoalInfo();
  }, [fetchMemberGoalInfo]);

  return (
    <div className='nk-body bg-white has-sidebar '>
      <div className='nk-app-root'>
        <div className='nk-main '>
          <div
            id='sidebar-menu'
            className={
              SidebarToggle === true
                ? 'sidebar-menu nk-sidebar is-light nk-sidebar-fixed nk-sidebar-submenu setting'
                : 'sidebar-menu nk-sidebar is-light nk-sidebar-fixed nk-sidebar-submenu setting toggle'
            }
            data-content='sidebarMenu'
          >
            <SideNav
              {...props}
              setSidebarToggle={setSidebarToggle}
              SidebarToggle={SidebarToggle}
            />
            <SubSideNav
              {...props}
              setSidebarToggle={setSidebarToggle}
              SidebarToggle={SidebarToggle}
            />
          </div>

          <div className='nk-wrap '>
            <div id='header' className='nk-header-fixed'>
              <Header
                SidebarToggle={SidebarToggle}
                setSidebarToggle={setSidebarToggle}
                {...props}
              />
            </div>

            <div className='nk-content nk-content-fluid'>{children}</div>
            {props.userDetails && props.userDetails.role === 'member' && (
              <div id='footer' className='nk-footer-fixed'>
                <MemberGoalBar
                  {...props}
                  memberGoalData={memberGoalData}
                  fetchMemberGoalInfo={memberGoal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
  // }
};

export default SettingsLayout;
