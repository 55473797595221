import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './Assets/css/dashlite.css';
import './Assets/css/main.min.css';
import 'bootstrap/dist/js/bootstrap';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
// import { createStore,applyMiddleware } from 'redux'
import Loading from './Components/loading/Loading.componnet';
import { Layout } from './Utility/context/layout';
import rootReducer from './Redux/reducer';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import ReduxThunk from 'redux-thunk'
// import logger from 'redux-logger'
// import env from "react-dotenv";

const store = createStore(rootReducer);
// const createStoreWithMiddleware = applyMiddleware(ReduxThunk, logger, )(store);
const LazyApp = lazy(() => import('./routes'));

ReactDOM.render(
  <CookiesProvider>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <Layout>
          <ToastContainer position='top-right' type='success' theme='dark' />
          <LazyApp />
        </Layout>
      </Provider>
    </Suspense>
  </CookiesProvider>,
  document.getElementById('root')
);
