import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoiceDollar,
  faEnvelope,
  faUserCog,
  faWrench,
  faCog,
} from '@fortawesome/free-solid-svg-icons';

const SubSideNav = (props) => {
  return (
    <div className='nk-sidebar-sub show active has-border' id='side-nav-list'>
      <div className='nk-sidebar-element nk-sidebar-head'>
        <div className='text-3 text-semibold'>Settings</div>
      </div>
      {/* <!-- .nk-sidebar-element --> */}
      <div className='nk-sidebar-element'>
        <div className='nk-sidebar-body'>
          <div className='nk-sidebar-content'>
            <div className='nk-sidebar-menu pt-1' data-simplebar>
              <ul className='nk-menu px-0'>
                {/* <!-- .nk-menu-item --> */}
                {props.userDetails.role !== 'member' && (
                  <li className='nk-menu-item'>
                    <NavLink
                      activeClassName='active'
                      to={`/${props.match.params.company_id}/invoice-settings`}
                      className='nk-menu-link'
                    >
                      {/* <span className='nk-menu-icon icon-invoice-settings icon-sm'></span> */}
                      <FontAwesomeIcon icon={faCog} className='mr-2' />
                      <span className='nk-menu-text'>Invoice Settings</span>
                    </NavLink>
                  </li>
                )}
                {/* <!-- .nk-menu-item --> */}
                <li className='nk-menu-item'>
                  <NavLink
                    activeClassName='active'
                    id='calls'
                    to={`/${props.match.params.company_id}/email-template`}
                    className='nk-menu-link'
                  >
                    {/* <span className='nk-menu-icon icon-email-template icon-sm'></span> */}
                    <FontAwesomeIcon icon={faEnvelope} className='mr-2' />

                    <span className='nk-menu-text'>Email Templates</span>
                  </NavLink>
                </li>
                {/* <!-- .nk-menu-item --> */}
                {props.userDetails.role !== 'member' && (
                  <li className='nk-menu-item'>
                    <NavLink
                      activeClassName='active'
                      id='calls'
                      to={`/${props.match.params.company_id}/payment-methods`}
                      className='nk-menu-link'
                    >
                      {/* <span className='nk-menu-icon icon-payment icon-sm'></span> */}
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        className='mr-2'
                      />

                      <span className='nk-menu-text'>Payment Methods</span>
                    </NavLink>
                  </li>
                )}
                <li className='nk-menu-item'>
                  <NavLink
                    activeClassName='active'
                    id='calls'
                    to={`/${props.match.params.company_id}/your-account`}
                    className='nk-menu-link'
                  >
                    {/* <span className='nk-menu-icon icon-user-check icon-sm'></span> */}
                    <FontAwesomeIcon icon={faUserCog} className='mr-2' />

                    <span className='nk-menu-text'>Your Account</span>
                  </NavLink>
                </li>
                {props.userDetails.role !== 'member' && (
                  <li className='nk-menu-item'>
                    <NavLink
                      activeClassName='active'
                      id='calls'
                      to={`/${props.match.params.company_id}/company-settings`}
                      className='nk-menu-link'
                    >
                      {/* <span className='nk-menu-icon icon-company-settings icon-sm'></span> */}
                      <FontAwesomeIcon icon={faWrench} className='mr-2' />

                      <span className='text-8 text-md nk-menu-text'>
                        Company Settings
                      </span>
                    </NavLink>
                  </li>
                )}
                {/* <!-- .nk-menu-item --> */}
              </ul>
            </div>
          </div>{' '}
          {/*<!-- .nk-sidebar-content -->*/}
        </div>
        {/*<!-- .nk-sidebar-body -->*/}
      </div>{' '}
      {/* <!-- .nk-sidebar-element -->*/}
    </div>
  );
};

export default SubSideNav;
