/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import icon_received from '../../Assets/images/icon-received.svg';
import icon_opened from '../../Assets/images/icon-opened.svg';
import { NavLink } from 'react-router-dom';
import AxiosInstance from '../../Utility/axios';
import classNames from 'classnames';

const Notification = (props) => {
  const [notificationData, setNotificationData] = useState([]);

  const markNotificationAsRead = () => {
    AxiosInstance.patch(`/logs/${props.match.params.company_id}/`).then(
      (response) => {
        if (response.status === 200) {
          getNotificationData();
        }
      }
    );
  };

  const getNotificationData = () => {
    AxiosInstance.get(`/logs/${props.match.params.company_id}`).then(
      (response) => {
        if (response.status === 200) {
          setNotificationData(response.data);
        }
      }
    );
  };

  useEffect(() => {
    AxiosInstance.get(`/logs/${props.match.params.company_id}`).then(
      (response) => {
        if (response.status === 200) {
          setNotificationData(response.data);
        }
      }
    );
  }, [props.match.params.company_id, props.showMenu]);

  return (
    <div className='notification'>
      <div className='notification__header'>
        <h3 className='text-3 text-semibold mb-0'>Notifications</h3>
        <button className='btn btn-plaintext' onClick={markNotificationAsRead}>
          Mark all as read
        </button>
      </div>
      <div className='notification__block'>
        <div className='notification__block-head'>
          <span>New </span>
        </div>
        <ul className='notification__block-list'>
          {notificationData.map((notification, index) => (
            <li
              key={index}
              className={classNames('notification__block-list-item', {
                ' status-unread': !notification.is_read,
              })}
            >
              <figure className='notification__img received mb-0'>
                <img
                  src={
                    notification.notification_type === 'Payment Received'
                      ? icon_received
                      : icon_opened
                  }
                  alt=''
                />
              </figure>
              <p>
                <span className='ht ht-title'>
                  {notification.notification_type}
                </span>{' '}
                {notification.description}
                {notification.notification_type !== null &&
                  notification.notification_type !== 'Invoice Opened' && (
                    <NavLink
                      target='_blank'
                      to={`/${props.match.params.company_id}/invoice/${
                        JSON.parse(notification.log.new_data)[0].fields.token
                      }/view`}
                    >
                      {' '}
                      &nbsp; View Details
                    </NavLink>
                  )}
              </p>
            </li>
          ))}

          {/* <li className='notification__block-list-item status-unread'>
            <figure className='notification__img opened mb-0'>
              <img src={icon_opened} alt='' />
            </figure>
            <p>
              <span className='ht ht-title'>Invoice Opened.</span> Your
              invoice#1123 was opened by Dinesh Silwal on{' '}
              <span className='ht ht-date'>Jan 12, 2021.</span>
            </p>
          </li>
          <li className='notification__block-list-item'>
            <figure className='notification__img received mb-0'>
              <img src={icon_received} alt='' />
            </figure>
            <p>
              <span className='ht ht-title'>Payment Received.</span> Your
              invoice#1123 received payment from Dinesh Silwal.
              <NavLink to=''>View Details</NavLink>
            </p>
          </li>
          <li className='notification__block-list-item status-unread'>
            <figure className='notification__img received mb-0'>
              <img src={icon_received} alt='' />
            </figure>
            <p>
              <span className='ht ht-title'>Payment Received.</span> Your
              invoice#1123 received payment from Dinesh Silwal.
              <NavLink to=''>View Details</NavLink>
            </p>
          </li> */}
        </ul>
      </div>
      {/* <div className='notification__block'>
        <div className='notification__block-head'>
          <span>Today</span>
        </div>
        <ul className='notification__block-list'>
          <li className='notification__block-list-item'>
            <figure className='notification__img opened mb-0'>
              <img src={icon_opened} alt='' />
            </figure>
            <p>
              <span className='ht ht-title'>Invoice Opened.</span> Your
              invoice#1123 was opened by Dinesh Silwal on{' '}
              <span className='ht ht-date'>Jan 12, 2021.</span>
            </p>
          </li>
          <li className='notification__block-list-item'>
            <figure className='notification__img received mb-0'>
              <img src={icon_received} alt='' />
            </figure>
            <p>
              <span className='ht ht-title'>Payment Received.</span> Your
              invoice#1123 received payment from Dinesh Silwal.
              <NavLink to=''>View Details</NavLink>
            </p>
          </li>
          <li className='notification__block-list-item'>
            <figure className='notification__img received mb-0'>
              <img src={icon_received} alt='' />
            </figure>
            <p>
              <span className='ht ht-title'>Payment Received.</span> Your
              invoice#1123 received payment from Dinesh Silwal.
              <NavLink to=''>View Details</NavLink>
            </p>
          </li>
          <li className='notification__block-list-item'>
            <figure className='notification__img received mb-0'>
              <img src={icon_received} alt='' />
            </figure>
            <p>
              <span className='ht ht-title'>Payment Received.</span> Your
              invoice#1123 received payment from Dinesh Silwal.
              <NavLink to=''>View Details</NavLink>
            </p>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default Notification;
