import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { History } from '../history';
// import Cookies from 'js-cookie';
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_URL,
});
if (sessionStorage.getItem('data') !== null) {
  var access_token = JSON.parse(sessionStorage.getItem('data')).access_token;
}
// if (Cookies.get('at')) {
if (access_token) {
  AxiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${access_token}`;
}
// **Axios Intercepetor**
AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 ||
      (error.response.status === 403 &&
        (error.response.data.detail === 'access_token expired' ||
          error.response.data.detail === 'User not found'))
    ) {
      if(sessionStorage.getItem('data') !== null){
        toast.error('Your session has been expired!');
      }
      sessionStorage.removeItem('data');
      Cookies.remove('user');
      History.push('/login');
      delete AxiosInstance.defaults.headers.common['Authorization'];
      var timeout = setTimeout(()=> {
        window.location.reload()
        window.clearTimeout(timeout)
      }, 500)
    }
    // if (
    //   error.response.status === 403 &&
    //     error.response.data.detail === 'not active subscriptions!')
    //  {
    //   toast.error('Your subscription has been expired!');
    //   if(sessionStorage.getItem('data') !== null){
    //     var subscription = JSON.parse(sessionStorage.getItem('data')).subscription
    //     var company = JSON.parse(sessionStorage.getItem('data')).company
    //     if(!subscription.is_trial && !subscription.is_subscribed){
    //       History.push(`/${company[0].id}/your-account`)
    //     }
    //   }
    // }
    // if (error.response.status === 403) {
    //   // sessionStorage.removeItem('data');
    //   // Cookies.remove('user');
    //   // toast.error('Your are unauthorized to perform this action!');
    //   // History.push('/unauthorized');
    // }
    return Promise.reject(error);
  }
);
// AxiosInstance.interceptors.request.use(function(request){
//   return request
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// })
export default AxiosInstance;
