import React from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangeFilter = (props) => {
  const { setRange, range, disabledFromDate } = props;
  // const direction = window.matchMedia("(max-width: 1199px)").matches ? 'vertical' : 'horizontal';
  return (
    <>
      <DateRangePicker
        onChange={(item) => setRange(item.selection)}
        color='#4dc647'
        rangeColors={['#4dc647']}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        minDate={disabledFromDate}
        maxDate={new Date()}
        ranges={[range]}
        direction='horizontal'
        // editableDateInputs = {true}
      />
    </>
  );
};

export default DateRangeFilter;
