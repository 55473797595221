import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../Assets/css/memberGoal.css';
import { History } from '../../history';

const MemberGoalBar = (props) => {
  const { memberGoalData } = props;

  let body = document.getElementsByTagName('body')[0]
  console.log(body)
  body.style.marginBottom = "134px";

  return (
    <>
      {Object.keys(memberGoalData).length > 0 && (
        <div className='member-goal '>
          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 row member-goal-container'>
            <div className='col-xl-2 col-lg-2 col-md-12 col-sm-12 member-goal-container-first '>
              <h6>Amount Invoiced!</h6>
              <h4>
                {memberGoalData.currency}{' '}
                {parseFloat(memberGoalData.invoiced_amount).toFixed(2)}
              </h4>
            </div>
            <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 member-goal-container-second'>
              <div className='counter-main-text mb-0 '>
                <p>
                  {parseFloat(memberGoalData.invoiced_percentage).toFixed(2)}%
                  of {memberGoalData.currency} {parseFloat(memberGoalData.invoiced_amount + memberGoalData.invoice_amount_left).toFixed(2)}{' '}
                  Goal
                </p>
                <p>
                  {memberGoalData.currency}{' '}
                  {parseFloat(memberGoalData.invoice_amount_left).toFixed(2)}{' '}
                  left in {memberGoalData.days_left} days
                </p>
              </div>
              <div className='py-2'>
                <ProgressBar
                  now={parseFloat(memberGoalData.invoiced_percentage).toFixed(
                    2
                  )}
                  variant='success'
                  label={`${parseFloat(
                    memberGoalData.invoiced_percentage
                  ).toFixed(2)}%`}
                  bsPrefix='progress_bar'
                  animated={true}
                />
              </div>
              <div>
                <p className='text-left'>
                  {moment(new Date(memberGoalData.start_date)).format('ll')} -{' '}
                  {moment(new Date(memberGoalData.end_date)).format('ll')} -{' '}
                  {memberGoalData.invoiced_count} Invoice sent
                </p>
              </div>
            </div>
            <div className='col-xl-2 col-lg-2 col-md-6 col-sm-12 member-goal-container-third'>
              <Button
                className='btn btn-primary btn-sm'
                variant='primary'
                onClick={() =>
                  History.push(
                    `/${props.match.params.company_id}/invoices?show_invoice=true`
                  )
                }
              >
                <em className='icon ni ni-plus-circle-fill'></em>
                <span>New Invoice</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemberGoalBar;
