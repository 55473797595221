import React from 'react';
import { History } from '../../history';
import AxiosInstance from '../axios';

const ContextCompany = React.createContext();

class Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyDetails: {},
      userDetails: {},
      range: {
        startDate: new Date(
          new Date(new Date().toISOString()).setHours(0, 0, 0)
        ),
        endDate: new Date(
          new Date(new Date().toISOString()).setHours(23, 59, 59)
        ),
        key: 'selection',
      },
    };
  }

  componentDidMount() {
    this.getCompanyDetails();
    this.setUserDetails();

    // redirection
    if (sessionStorage.getItem('data') !== null) {
      var subscription = JSON.parse(
        sessionStorage.getItem('data')
      ).subscription;
      var company = JSON.parse(sessionStorage.getItem('data')).company;

      if (subscription !== null && !subscription.is_trial) {
        if (!subscription.is_subscribed) {
          History.push(`/${company[0].id}/your-account`);
        }
      }
    }
  }

  setUserDetails = () => {
    if (sessionStorage.getItem('data') !== null) {
      var userDetails = JSON.parse(sessionStorage.getItem('data'));
    }
    this.setState({
      userDetails: userDetails,
    });
  };

  getCompanyDetails = (id) => {
    let companyId = this.props.match.params.company_id;
    if (id) {
      companyId = id;
    }

    AxiosInstance.get(`companies/${companyId}`).then((response) => {
      if (response.status === 200) {
        this.setState({
          companyDetails: response.data,
        });

        // var date = new Date();
        // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.setState({
          range: {
            startDate: new Date(response.data.created_at),
            endDate: new Date(new Date(new Date().toISOString()).setHours(23,59,59)),
            // startDate: new Date(
            //   new Date(new Date(firstDay).setHours(0, 0, 0)).toISOString()
            // ),
            // endDate: new Date(
            //   new Date(new Date(lastDay).setHours(23, 59, 59)).toISOString()
            // ),
            key: 'selection',
          },
        });
      }
    });
  };

  setRange = (range) => {
    if (
      new Date(range.startDate).toTimeString() ===
      new Date(range.endDate).toTimeString()
    ) {
      range.endDate = new Date(new Date(range.endDate).setHours(23, 59, 59));
    }
    this.setState({
      range: range,
    });
  };

  render() {
    const { children } = this.props;
    const { companyDetails, userDetails, range } = this.state;

    return (
      <ContextCompany.Provider
        value={{
          selectedCompany: companyDetails,
          userDetails: userDetails,
          setUserDetails: this.setUserDetails,
          getCompanyDetails: this.getCompanyDetails,
          range: range,
          setRange: this.setRange,
        }}
      >
        {children}
      </ContextCompany.Provider>
    );
  }
}

export { Company, ContextCompany };
