import React, { useCallback, useEffect, useState } from 'react';
import Header from '../Components/header/Header.component';
import MemberGoalBar from '../Components/member/memberGoalBar.component';
import SideNav from '../Components/sidebar/SideNav.component';
import '../Assets/css/footer.css';
import AxiosInstance from '../Utility/axios';

const DashboardLayout = (props) => {
  const { children } = props;
  const [SidebarToggle, setSidebarToggle] = useState(true);
  const [memberGoalData, setMemberGoalData] = useState({});

  const memberGoal = () => {
    if (props.userDetails.role === 'member') {
      AxiosInstance.get(
        `/company/${props.match.params.company_id}/members/invoice_goal`
      ).then((response) => {
        if (response.status === 200) {
          setMemberGoalData(response.data);
        }
      });
    }
  };

  const fetchMemberGoalInfo = useCallback(() => {
    if (props.userDetails.role === 'member') {
      AxiosInstance.get(
        `/company/${props.match.params.company_id}/members/invoice_goal`
      ).then((response) => {
        if (response.status === 200) {
          setMemberGoalData(response.data);
        }
      });
    }
  }, [props.match.params.company_id, props.userDetails.role]);

  useEffect(() => {
    fetchMemberGoalInfo();
  }, [fetchMemberGoalInfo]);

  return (
    <div className='nk-body bg-white has-sidebar '>
      <div className='nk-app-root'>
        <div className='nk-main '>
          <div
            id='sidebar-menu'
            // className='sidebar-menu nk-sidebar is-light nk-sidebar-fixed'
            className={
              SidebarToggle === true
                ? 'sidebar-menu nk-sidebar is-light nk-sidebar-fixed'
                : 'sidebar-menu nk-sidebar is-light nk-sidebar-fixed toggle'
            }
            data-content='sidebarMenu'
          >
            <SideNav
              {...props}
              setSidebarToggle={setSidebarToggle}
              SidebarToggle={SidebarToggle}
            />
          </div>

          <div className='nk-wrap '>
            <div id='header' className='nk-header-fixed'>
              <Header
                {...props}
                setSidebarToggle={setSidebarToggle}
                SidebarToggle={SidebarToggle}
              />
            </div>

            <div className='nk-content nk-content-fluid'>{children}</div>
            {props.userDetails && props.userDetails.role === 'member' && (
              <div id='footer' className='nk-footer-fixed'>
                <MemberGoalBar
                  {...props}
                  memberGoalData={memberGoalData}
                  fetchMemberGoalInfo={memberGoal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
