/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import outinvoice_logo from '../../Assets/svg/logo.svg';
import { Alert } from 'react-bootstrap';
import { ContextCompany } from '../../Utility/context/company';
import AxiosInstance from '../../Utility/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import icon_calendar from '../../Assets/images/icons/svg/icon-calendar.svg';
import icon_bell from '../../Assets/images/icons/svg/icon-bell.svg';
import DateRangePicker from '../action/filter/DateRangeFilter.component';
import { Dropdown } from 'react-bootstrap';
import Notification from '../notification/Notification.component';
import '../../Assets/css/header.css';
import { NavLink } from 'react-router-dom';
// import { addHours } from 'date-fns';
import moment from 'moment';

const Header = (props) => {
  const { SidebarToggle, setSidebarToggle } = props;
  const [showAlert, setShowAlert] = useState(true);

  const [showMenu, setShowMenu] = useState(true);

  const { userDetails, selectedCompany, range, setRange } =
    useContext(ContextCompany);
  const [Heading, setHeading] = useState('Invoices');
  const [showCalander, setShowCalander] = useState(true);

  useEffect(() => {
    if (Object.keys(userDetails).length > 0) {
      setShowAlert(!userDetails.is_verified);
    }

    if (
      props.match.url &&
      props.match.url.match('clients') !== null &&
      props.match.url.match('clients').length > 0
    ) {
      setHeading('Clients');
      setShowCalander(true);
    }
    if (
      props.match.url &&
      props.match.url.match('invoices') !== null &&
      props.match.url.match('invoices').length > 0
    ) {
      setHeading('invoices');
      setShowCalander(true);
    }
    if (
      props.match.url &&
      props.match.url.match('members') !== null &&
      props.match.url.match('members').length > 0
    ) {
      setHeading('members');
      setShowCalander(false);
    }
    if (
      props.match.url &&
      props.match.url.match('reports') !== null &&
      props.match.url.match('reports').length > 0
    ) {
      setHeading('reports');
      setShowCalander(true);
    }

    if (
      props.match.url &&
      props.match.url.match('invoice-settings') !== null &&
      props.match.url.match('invoice-settings').length > 0
    ) {
      setHeading('invoice settings');
      setShowCalander(false);
    }

    if (
      props.match.url &&
      props.match.url.match('email-template') !== null &&
      props.match.url.match('email-template').length > 0
    ) {
      setHeading('Email template');
      setShowCalander(false);
    }

    if (
      props.match.url &&
      props.match.url.match('payment-methods') !== null &&
      props.match.url.match('payment-methods').length > 0
    ) {
      setHeading('payment methods');
      setShowCalander(false);
    }

    if (
      props.match.url &&
      props.match.url.match('your-account') !== null &&
      props.match.url.match('your-account').length > 0
    ) {
      setHeading('your account');
      setShowCalander(false);
    }

    if (
      props.match.url &&
      props.match.url.match('company-settings') !== null &&
      props.match.url.match('company-settings').length > 0
    ) {
      setHeading('company settings');
      setShowCalander(false);
    }
  }, [userDetails, props]);

  const verifyEmail = () => {
    AxiosInstance.post('/accounts/send-email-verification-link')
      .then((response) => {
        toast('Email send sucessfully, check your email!');
      })
      .catch((err) => {
        toast('Error sending email!');
      });
  };

  const CalculateDateRangeSelection = () => {
    if (
      new Date(range.startDate).setHours(0, 0, 0, 0) ===
        new Date(selectedCompany.created_at).setHours(0, 0, 0, 0) &&
      new Date(range.endDate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
    ) {
      return 'Lifetime';
    }

    if (
      new Date(range.startDate).setHours(0, 0, 0, 0) ===
      new Date(range.endDate).setHours(0, 0, 0, 0)
    ) {
      return `${moment(range.startDate).format('LL')}`;
    }

    return `${moment(range.startDate).format('LL')} - ${moment(
      range.endDate
    ).format('LL')}`;
  };

  return (
    <div>
      <div className='nk-brand-mobile d-xl-none'>
        <div className='nk-sidebar-brand '>
          <NavLink
            to={`/${props.match.params.company_id}/invoices`}
            className='logo-link nk-sidebar-logo'
          >
            <img
              className='logo-img'
              src={outinvoice_logo}
              srcSet={outinvoice_logo}
              alt='logo'
            />
          </NavLink>
        </div>

        <div
          className='nk-header-tools pb-0 item-center warning-click-here'
          style={{ marginTop: '0.rem' }}
        >
          {userDetails.role !== 'member' && (
            <Alert
              className='alert alert-warning alert-dismissible fade show'
              show={showAlert}
              variant='danger'
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <p>
                Your account is not verified, Please verify your account!{' '}
                <button
                  type='button'
                  className='success text-primary'
                  style={{
                    cursor: 'pointer',
                    background: 'none',
                    border: 'none',
                  }}
                  onClick={() => verifyEmail()}
                >
                  click here!
                </button>
              </p>
            </Alert>
          )}
        </div>

        <div className='nk-menu-trigger d-xl-none ml-n1'>
          <button
            style={{ background: 'none', border: 'none' }}
            type='button'
            className='nk-nav-toggle nk-quick-nav-icon'
            onClick={() => setSidebarToggle(!SidebarToggle)}
          >
            <em className='icon ni ni-menu'></em>
          </button>
        </div>
      </div>
      <div className='nk-header is-light'>
        <div className='container-fluid'>
          <div className='nk-header-wrap'>
            <div className='nk-header-news'>
              <h1 className='nk-block-title text-3 text-semibold'>
                {/* <span>{page_name}</span> */}
                {/* <span>{Heading}</span> */}
                {Heading}
              </h1>
            </div>

            <div
              className='nk-header-tools pb-0 item-center warning-click-here'
              style={{ marginTop: '-1rem' }}
            >
              {userDetails.role !== 'member' && (
                <Alert
                  show={showAlert}
                  variant='danger'
                  onClose={() => setShowAlert(false)}
                  dismissible
                >
                  <p>
                    Your account is not verified, Please verify your account!
                    <button
                      type='button'
                      className='success text-primary'
                      style={{
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                      }}
                      onClick={() => verifyEmail()}
                    >
                      click here!
                    </button>
                  </p>
                </Alert>
              )}
            </div>

            <div className='nk-header-tools pb-0 item-center text-dark header-dropdown'>
              {showCalander && selectedCompany.created_at && (
                <Dropdown>
                  <Dropdown.Toggle
                    className='btn btn-default btn-sm justify-content-start lifetime'
                    id='dropdown-basic'
                  >
                    <div className='icon-wrap'>
                      <img src={icon_calendar} alt='' />
                      <span className='ml-2'>
                        {CalculateDateRangeSelection()}
                      </span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <DateRangePicker
                      range={range}
                      setRange={setRange}
                      disabledFromDate={
                        selectedCompany.created_at
                          ? new Date(selectedCompany.created_at)
                          : new Date()
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <div className='notification-dropdown pl-3'>
                <Dropdown>
                  <Dropdown.Toggle className=' px-2 bg-transparent'>
                    <span
                      onClick={() => setShowMenu(!showMenu)}
                      style={{ background: 'none', border: 'none' }}
                      type='button'
                      className=' nt-new'
                      aria-labelledby='notification'
                    >
                      {/* <img src={icon_bell} alt='' /> */}
                    </span>
                    <img src={icon_bell} alt='' />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Notification {...props} showMenu={showMenu} />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer position='top-right' type='success' theme='dark' /> */}
    </div>
  );
};

export default Header;
