import { passwordStrength } from 'check-password-strength';

class ValidationHelper {
  static EmailValidation = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  static PasssWordValidation = (password) => {
    if (password.length <= 6) {
      return false;
    }
    return true;
  };

  static StrongPasssWordValidation = (password) => {
    const re =
      /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/;
    return re.test(String(password).toLowerCase());
  };

  static ValidatePasswordStrength = (password, setPassStrengthStatus) => {
    setPassStrengthStatus(passwordStrength(password).value);
  };

  static SignupPasssWordValidation = (password, setPassStrengthStatus) => {
    let len = password.length;
    var re = {
      capital: /[A-Z]/,
      lower: /[a-z]/,
      digit: /[0-9]/,
      specialCharacterRegx: /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/,
      full: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
    };

    if (len === 0) {
      setPassStrengthStatus('');
    }
    if (
      re.digit.test(password) ||
      re.lower.test(password) ||
      re.capital.test(password) ||
      re.specialCharacterRegx.test(password)
    ) {
      setPassStrengthStatus('weak');
    }
    if (
      (re.digit.test(password) && re.lower.test(password)) ||
      (re.lower.test(password) && re.specialCharacterRegx.test(password)) ||
      (re.digit.test(password) && re.specialCharacterRegx.test(password)) ||
      (re.capital.test(password) && re.digit.test(password)) ||
      (re.capital.test(password) && re.specialCharacterRegx.test(password))
    ) {
      setPassStrengthStatus('medium');
    }
    if (
      (re.lower.test(password) &&
        re.digit.test(password) &&
        re.capital.test(password) &&
        re.specialCharacterRegx.test(password) &&
        len > 8) ||
      (re.specialCharacterRegx.test(password) &&
        re.lower.test(password) &&
        re.digit.test(password) &&
        len > 8) ||
      (re.digit.test(password) &&
        re.specialCharacterRegx.test(password) &&
        re.capital.test(password) &&
        len > 8)
    ) {
      setPassStrengthStatus('strong');
    }
    if (re.full.test(password)) {
      setPassStrengthStatus('stronger');
      // return [" "];
    }
  };

  static CompanyNameValidation = (company_name) => {
    const re = /^[a-zA-Z]/;
    return re.test(company_name);
    // if(company_name.length < 3) return false;
    // return true
  };

  static OnlyNumberContain = (price) => {
    const re = /^[0-9]*$/;

    return re.test(price);
  };

  static TwoDigitvalidationHelper = (value) => {
    const re = /^\d*(\d\.\d{0,2})?$/;
    return re.test(parseFloat(value));
  };
}

export default ValidationHelper;
