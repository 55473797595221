/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import outinvoice_logo from '../../Assets/svg/logo.svg';
import icon_user from '../../Assets/images/avatar/icon-user.jpg';
import { Dropdown } from 'react-bootstrap';
import AxiosInstance from '../../Utility/axios';
import { History } from '../../history';
import AddCompany from '../company/AddCompany.component';
import { NavLink } from 'react-router-dom';
import { ContextCompany } from '../../Utility/context/company';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Assets/css/sideNavbar.css';
import {
  faFileInvoiceDollar,
  faEnvelope,
  faUserCog,
  faWrench,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import NameSplitHelper from '../../Utility/helpers/nameSplit.helper';

const SideNav = (props) => {
  var UserName = props.userDetails.name;
  const { SidebarToggle, setSidebarToggle, selectedCompany } = props;
  const [CompList, setCompList] = useState([]);
  const [addComp, setAddComp] = useState(false);
  const { getCompanyDetails } = useContext(ContextCompany);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = () => {
    AxiosInstance.get('/companies/')
      .then(function (response) {
        setCompList(response.data);
      })
      .catch(function (error) {});
  };

  // if (props.selectedCompany.name === undefined) {
  //   var selectedCompName = '';
  // } else {
  //   selectedCompName = Selected.substring(0, 15);
  // }

  if (props.userDetails.name === undefined) {
    var User = '';
  } else {
    User = UserName.substring(0, 15);
    UserName = User.split(' ');
  }

  return (
    <div className='nk-sidebar-main has-border'>
      {/* <!-- .nk-sidebar-element -->*/}
      <div className='nk-sidebar-element nk-sidebar-head flex-column'>
        <div className='d-flex justify-content-between w-100'>
          <div className='nk-sidebar-brand '>
            <NavLink
              to={`/${props.selectedCompany.id}/invoices`}
              activeClassName='active'
              className='logo-link nk-sidebar-logo'
            >
              <img
                className='logo-img'
                src={outinvoice_logo}
                srcSet={outinvoice_logo}
                alt='logo'
              />
            </NavLink>
          </div>
          <div className='nk-menu-trigger d-xl-none ml-n1'>
            <button
              style={{ background: 'none', border: 'none' }}
              // type="button"
              className='nk-nav-toggle nk-quick-nav-icon p-0 m-0'
              data-target='sidebarMenu'
            >
              <em
                className='icon ni ni-cross'
                onClick={() => setSidebarToggle(!SidebarToggle)}
              ></em>
            </button>
          </div>
        </div>

        {/* <!-- nk sidebar company switcher --> */}
      </div>
      <div className='nk-sidebar-company text-center'>
        <Dropdown>
          <Dropdown.Toggle
            variant='transparent'
            className='btn-company sidebar-tool__tip'
            id='dropd'
            style={{ whiteSpace: 'normal' }}
          >
            {props.selectedCompany.logo !== null ? (
              <img alt='' src={props.selectedCompany.logo} />
            ) : (
              <div className='form-check py-2 mr-1'>
                <span
                  id='dynamic-image-show'
                  className='text-uppercase company-name-img-alt mr-0'
                >
                  {props.selectedCompany.name.charAt(0)}
                </span>
              </div>
            )}
            {selectedCompany.name !== undefined ? (
              <>
                {NameSplitHelper.SidebarCompanyName(
                  selectedCompany.name
                )[0].split(' ').length < 2 &&
                NameSplitHelper.SidebarCompanyName(selectedCompany.name)
                  .length > 15 ? (
                  <>
                    <span>
                      {NameSplitHelper.SidebarCompanyName(
                        selectedCompany.name
                      ).slice(0, 15)}
                      <br></br>
                      {NameSplitHelper.SidebarCompanyName(
                        selectedCompany.name
                      ).slice(15, 30)}
                    </span>
                    {/* <span>{NameSplitHelper.SidebarCompanyName(selectedCompany.name)[0].slice(15,30)}</span> */}
                  </>
                ) : (
                  <span>
                    {NameSplitHelper.SidebarCompanyName(selectedCompany.name)}
                  </span>
                )}
              </>
            ): null}

            <em className='icon ni ni-chevron-down ml-auto'></em>
            {/* <span className='tool__tip__text'>{selectedCompany.name}</span> */}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className='w-100'
            style={{ maxHeight: '400px', overflowY: 'auto' }}
          >
            {CompList.map((company) => {
              var Cname = company.name.charAt(0);
              var logo = company.logo;
              var SelectedComName = company.name.split(' ');

              return (
                <Dropdown.Item
                  onClick={() => {
                    // History.push(`/${company.id}/invoices`, {
                    //   companyId: company.id,
                    // });
                    History.push(
                      props.location.pathname.replace(
                        `${props.match.params.company_id}`,
                        company.id
                      ),
                      {
                        companyId: company.id,
                      }
                    );
                    getCompanyDetails(company.id);
                  }}
                  key={company.id}
                >
                  <div className='company-list'>
                    <div className='form-check py-2'>
                      <input
                        className='form-check-input my-radio'
                        type='radio'
                        name='flexRadioDefault'
                        id='flexRadioDefault1'
                        onChange={() => true}
                        checked={
                          parseInt(props.match.params.company_id) === company.id
                            ? true
                            : false
                        }
                      />
                      {logo !== null ? (
                        <>
                          <img
                            src={company.logo}
                            style={{ height: '20px', maxWidth: '35px' }}
                            alt=''
                          />
                        </>
                      ) : (
                        <>
                          <span
                            id='dynamic-image-show'
                            className='text-uppercase company-name-img-alt'
                          >
                            {Cname}
                          </span>
                        </>
                      )}

                      {company.name === undefined ? (
                        <></>
                      ) : (
                        <label
                          className='form-check-label pl-1'
                          htmlFor='flexRadioDefault1'
                        >
                          {company.name.length >= 15 &&
                          SelectedComName.length > 1 ? (
                            <span>
                              {SelectedComName[0].length +
                                SelectedComName[1].length >=
                              15 ? (
                                <>{SelectedComName[0]}</>
                              ) : (
                                <>
                                  {SelectedComName[0]} {SelectedComName[1]}
                                </>
                              )}
                            </span>
                          ) : (
                            <span>{company.name}</span>
                          )}
                          {/* {company.name} */}
                        </label>
                      )}
                    </div>
                  </div>
                </Dropdown.Item>
              );
            })}

            {props.userDetails.role !== 'member' && (
              <div className='text-center'>
                <button
                  className='btn btn-primary'
                  aria-labelledby='company-add-drawer'
                  data-target='#addComapny'
                  onClick={() => {
                    setAddComp(true);
                  }}
                >
                  <span className='icon-plus'>
                    <svg
                      width='10'
                      height='10'
                      viewBox='0 0 10 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.33301 4.33337V0.333374H5.66634V4.33337H9.66634V5.66671H5.66634V9.66671H4.33301V5.66671H0.333008V4.33337H4.33301Z'
                        fill='white'
                      ></path>
                    </svg>
                  </span>
                  Add Company
                </button>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* <!-- .nk-sidebar-element --> */}
      <div className='nk-sidebar-element'>
        <div className='nk-sidebar-body'>
          <div className='nk-sidebar-content'>
            <div className='nk-sidebar-menu pt-2' data-simplebar>
              <ul className='nk-menu px-0'>
                {/* <!-- .nk-menu-item --> */}
                <li className='nk-menu-item'>
                  <NavLink
                    // activeClassName='active'
                    to={`/${props.selectedCompany.id}/invoices`}
                    id='invoices'
                    className='nk-menu-link'
                  >
                    <span className='nk-menu-icon icon-invoice icon-sm'>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.7002 3.29999H13.2002C13.598 3.29999 13.9795 3.45802 14.2609 3.73933C14.5422 4.02063 14.7002 4.40216 14.7002 4.79999V15.3C14.7002 15.6978 14.5422 16.0793 14.2609 16.3606C13.9795 16.642 13.598 16.8 13.2002 16.8H4.2002C3.80237 16.8 3.42084 16.642 3.13954 16.3606C2.85823 16.0793 2.7002 15.6978 2.7002 15.3V4.79999C2.7002 4.40216 2.85823 4.02063 3.13954 3.73933C3.42084 3.45802 3.80237 3.29999 4.2002 3.29999H5.7002'
                          stroke='#56666D'
                          strokeWidth='1.6'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path>
                        <path
                          d='M10.9502 1.79999H6.4502C6.03598 1.79999 5.7002 2.13577 5.7002 2.54999V4.04999C5.7002 4.4642 6.03598 4.79999 6.4502 4.79999H10.9502C11.3644 4.79999 11.7002 4.4642 11.7002 4.04999V2.54999C11.7002 2.13577 11.3644 1.79999 10.9502 1.79999Z'
                          stroke='#56666D'
                          strokeWidth='1.6'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path>
                      </svg>
                    </span>
                    <span className='nk-menu-text'>Invoices</span>
                  </NavLink>
                </li>
                {/* <!-- .nk-menu-item --> */}

                <li className='nk-menu-item'>
                  <NavLink
                    // activeClassName='active'
                    to={`/${props.selectedCompany.id}/clients`}
                    id='clients'
                    className='nk-menu-link'
                  >
                    <span className='nk-menu-icon icon-clients icon-sm'>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.5996 4.26666H2.93294C2.19656 4.26666 1.59961 4.86362 1.59961 5.6V12.2667C1.59961 13.003 2.19656 13.6 2.93294 13.6H13.5996C14.336 13.6 14.9329 13.003 14.9329 12.2667V5.6C14.9329 4.86362 14.336 4.26666 13.5996 4.26666Z'
                          stroke='#56666D'
                          strokeWidth='1.6'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path>
                        <path
                          d='M10.9329 13.6V2.93331C10.9329 2.57969 10.7925 2.24055 10.5424 1.9905C10.2924 1.74045 9.95323 1.59998 9.59961 1.59998H6.93294C6.57932 1.59998 6.24018 1.74045 5.99013 1.9905C5.74009 2.24055 5.59961 2.57969 5.59961 2.93331V13.6'
                          stroke='#56666D'
                          strokeWidth='1.6'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path>
                      </svg>
                    </span>
                    <span className='nk-menu-text'>Clients</span>
                  </NavLink>
                </li>
                {/* <!-- .nk-menu-item --> */}

                {props.userDetails.role !== 'member' && (
                  <li className='nk-menu-item'>
                    <NavLink
                      // activeClassName='active'
                      id='members'
                      to={`/${props.match.params.company_id}/members`}
                      className='nk-menu-link'
                    >
                      <span className='nk-menu-icon icon-members icon-sm'>
                        <svg
                          width='18'
                          height='18'
                          viewBox='0 0 18 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0)'>
                            <path
                              d='M12.8999 15.3V13.8C12.8999 13.0043 12.5838 12.2413 12.0212 11.6787C11.4586 11.1161 10.6956 10.8 9.8999 10.8H3.8999C3.10425 10.8 2.34119 11.1161 1.77858 11.6787C1.21597 12.2413 0.899902 13.0043 0.899902 13.8V15.3'
                              stroke='#56666D'
                              strokeWidth='1.6'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></path>
                            <path
                              d='M6.8999 7.79999C8.55676 7.79999 9.8999 6.45684 9.8999 4.79999C9.8999 3.14313 8.55676 1.79999 6.8999 1.79999C5.24305 1.79999 3.8999 3.14313 3.8999 4.79999C3.8999 6.45684 5.24305 7.79999 6.8999 7.79999Z'
                              stroke='#56666D'
                              strokeWidth='1.6'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></path>
                            <path
                              d='M17.3999 15.3V13.8C17.3994 13.1353 17.1782 12.4896 16.7709 11.9643C16.3637 11.4389 15.7935 11.0637 15.1499 10.8975'
                              stroke='#56666D'
                              strokeWidth='1.6'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></path>
                            <path
                              d='M12.1499 1.89752C12.7952 2.06275 13.3672 2.43805 13.7756 2.96425C14.1841 3.49046 14.4058 4.13764 14.4058 4.80377C14.4058 5.4699 14.1841 6.11708 13.7756 6.64329C13.3672 7.1695 12.7952 7.5448 12.1499 7.71002'
                              stroke='#56666D'
                              strokeWidth='1.6'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            ></path>
                          </g>
                          <defs>
                            <clipPath id='clip0'>
                              <rect width='18' height='18' fill='white'></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <span className='nk-menu-text'>Members</span>
                    </NavLink>
                  </li>
                )}
                {/* <!-- .nk-menu-item --> */}

                <li className='nk-menu-item'>
                  <NavLink
                    // activeClassName='active'
                    id='reports'
                    to={`/${props.match.params.company_id}/reports`}
                    className='nk-menu-link'
                  >
                    <span className='nk-menu-icon icon-reports icon-sm'>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16.2076 12.2175C15.7305 13.3458 14.9842 14.3401 14.034 15.1135C13.0838 15.8868 11.9587 16.4156 10.7569 16.6536C9.55518 16.8916 8.31342 16.8316 7.14022 16.4788C5.96701 16.1261 4.89808 15.4913 4.02687 14.63C3.15567 13.7687 2.50872 12.707 2.14258 11.5379C1.77645 10.3688 1.70228 9.12785 1.92656 7.92346C2.15083 6.71907 2.66673 5.58796 3.42914 4.62901C4.19155 3.67006 5.17727 2.91247 6.30011 2.42249'
                          stroke='#56666D'
                          strokeWidth='1.6'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path>
                        <path
                          d='M16.8003 9.29999C16.8003 8.31507 16.6063 7.3398 16.2294 6.42986C15.8525 5.51992 15.3 4.69313 14.6036 3.99669C13.9072 3.30025 13.0804 2.7478 12.1704 2.37089C11.2605 1.99398 10.2852 1.79999 9.30029 1.79999V9.29999H16.8003Z'
                          stroke='#56666D'
                          strokeWidth='1.6'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path>
                      </svg>
                    </span>
                    <span className='nk-menu-text'>Reports</span>
                  </NavLink>
                </li>
                {/* <!-- .nk-menu-item --> */}
                <li className='nk-menu-item d-none d-xl-block'>
                  {' '}
                  <NavLink
                    activeClassName='active'
                    to={
                      props.userDetails.role === 'member'
                        ? `/${props.match.params.company_id}/your-account`
                        : `/${props.match.params.company_id}/invoice-settings`
                    }
                    id='settings'
                    className='nk-menu-link'
                  >
                    <span className='nk-menu-icon icon-settings icon-sm'>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.7999 4.20009C9.67775 4.32471 9.60933 4.49225 9.60933 4.66675C9.60933 4.84126 9.67775 5.0088 9.7999 5.13342L10.8666 6.20009C10.9912 6.32224 11.1587 6.39066 11.3332 6.39066C11.5077 6.39066 11.6753 6.32224 11.7999 6.20009L14.3132 3.68675C14.6485 4.42755 14.75 5.25291 14.6042 6.05285C14.4585 6.85279 14.0724 7.58932 13.4974 8.16427C12.9225 8.73923 12.1859 9.12531 11.386 9.27106C10.5861 9.41681 9.7607 9.31531 9.0199 8.98009L4.41324 13.5868C4.14802 13.852 3.78831 14.001 3.41324 14.001C3.03816 14.001 2.67845 13.852 2.41324 13.5868C2.14802 13.3215 1.99902 12.9618 1.99902 12.5868C1.99902 12.2117 2.14802 11.852 2.41324 11.5868L7.0199 6.98009C6.68468 6.2393 6.58318 5.41393 6.72893 4.61399C6.87468 3.81405 7.26076 3.07753 7.83572 2.50257C8.41067 1.92761 9.1472 1.54153 9.94714 1.39578C10.7471 1.25003 11.5724 1.35153 12.3132 1.68675L9.80657 4.19342L9.7999 4.20009Z'
                          stroke='#445668'
                          strokeWidth='1.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></path>
                      </svg>
                    </span>
                    <span className='nk-menu-text'>Settings</span>
                  </NavLink>
                </li>
                <li className='nk-menu-item d-xl-none'>
                  {/* <NavLink
                    activeClassName='active'
                    to={`/${props.match.params.company_id}/invoice-settings`}
                    id='settings'
                    className='nk-menu-link'
                  >
                    <button
                      style={{ background: 'none', border: 'none' }}
                      data-toggle='dropdown'
                      className='dropdown-toggle nk-menu-link'
                      id='dropdownMenuButton1'
                    >
                      <span className='nk-menu-icon icon-settings icon-sm'>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M9.7999 4.20009C9.67775 4.32471 9.60933 4.49225 9.60933 4.66675C9.60933 4.84126 9.67775 5.0088 9.7999 5.13342L10.8666 6.20009C10.9912 6.32224 11.1587 6.39066 11.3332 6.39066C11.5077 6.39066 11.6753 6.32224 11.7999 6.20009L14.3132 3.68675C14.6485 4.42755 14.75 5.25291 14.6042 6.05285C14.4585 6.85279 14.0724 7.58932 13.4974 8.16427C12.9225 8.73923 12.1859 9.12531 11.386 9.27106C10.5861 9.41681 9.7607 9.31531 9.0199 8.98009L4.41324 13.5868C4.14802 13.852 3.78831 14.001 3.41324 14.001C3.03816 14.001 2.67845 13.852 2.41324 13.5868C2.14802 13.3215 1.99902 12.9618 1.99902 12.5868C1.99902 12.2117 2.14802 11.852 2.41324 11.5868L7.0199 6.98009C6.68468 6.2393 6.58318 5.41393 6.72893 4.61399C6.87468 3.81405 7.26076 3.07753 7.83572 2.50257C8.41067 1.92761 9.1472 1.54153 9.94714 1.39578C10.7471 1.25003 11.5724 1.35153 12.3132 1.68675L9.80657 4.19342L9.7999 4.20009Z'
                            stroke='#445668'
                            strokeWidth='1.4'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></path>
                        </svg>
                      </span>
                      <span className='nk-menu-text'>Settings</span>
                    </button>
                  </NavLink> */}
                </li>
                <li className='nk-menu-item d-xl-none'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='transparent'
                      style={{ border: 'none' }}
                      className='nk-menu-link'
                    >
                      <span className='nk-menu-icon icon-settings icon-sm'>
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M9.7999 4.20009C9.67775 4.32471 9.60933 4.49225 9.60933 4.66675C9.60933 4.84126 9.67775 5.0088 9.7999 5.13342L10.8666 6.20009C10.9912 6.32224 11.1587 6.39066 11.3332 6.39066C11.5077 6.39066 11.6753 6.32224 11.7999 6.20009L14.3132 3.68675C14.6485 4.42755 14.75 5.25291 14.6042 6.05285C14.4585 6.85279 14.0724 7.58932 13.4974 8.16427C12.9225 8.73923 12.1859 9.12531 11.386 9.27106C10.5861 9.41681 9.7607 9.31531 9.0199 8.98009L4.41324 13.5868C4.14802 13.852 3.78831 14.001 3.41324 14.001C3.03816 14.001 2.67845 13.852 2.41324 13.5868C2.14802 13.3215 1.99902 12.9618 1.99902 12.5868C1.99902 12.2117 2.14802 11.852 2.41324 11.5868L7.0199 6.98009C6.68468 6.2393 6.58318 5.41393 6.72893 4.61399C6.87468 3.81405 7.26076 3.07753 7.83572 2.50257C8.41067 1.92761 9.1472 1.54153 9.94714 1.39578C10.7471 1.25003 11.5724 1.35153 12.3132 1.68675L9.80657 4.19342L9.7999 4.20009Z'
                            stroke='#445668'
                            strokeWidth='1.4'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></path>
                        </svg>
                      </span>
                      <span className='nk-menu-text'>Settings</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className='d-xl-none dropdown-setting border-0'
                      style={{ background: 'none' }}
                    >
                      {/* <Dropdown.Item className='nk-menu-item'> */}
                      <ul
                        className='dropdown--menu d-xl-none bg-transparent border-0'
                        style={{ boxShadow: 'none', width: '240px' }}
                        aria-labelledby='dropdownMenuButton1'
                      >
                        <li className='nk-menu-item sub-nav-item'>
                          <NavLink
                            activeClassName='active'
                            to={`/${props.match.params.company_id}/invoice-settings`}
                            className='nk-menu-link '
                            id='invoice-settings'
                          >
                            {/* <span className='nk-menu-icon icon-invoice-settings icon-sm'></span> */}
                            <FontAwesomeIcon icon={faCog} className='mr-2' />
                            <span className='nk-menu-text'>
                              Invoice Settings
                            </span>
                          </NavLink>
                        </li>
                        {/* <!-- .nk-menu-item --> */}
                        <li className='nk-menu-item sub-nav-item'>
                          <NavLink
                            activeClassName='active'
                            to={`/${props.match.params.company_id}/email-template`}
                            className='nk-menu-link '
                            id='email-templates'
                          >
                            {/* <span className='nk-menu-icon icon-email-template icon-sm'></span> */}
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className='mr-2'
                            />

                            <span className='nk-menu-text'>
                              Email Templates
                            </span>
                          </NavLink>
                        </li>
                        <li className='nk-menu-item sub-nav-item'>
                          <NavLink
                            activeClassName='active'
                            to={`/${props.match.params.company_id}/payment-methods`}
                            className='nk-menu-link '
                            id='payment-methods'
                          >
                            {/* <span className='nk-menu-icon icon-payment icon-sm'></span> */}
                            <FontAwesomeIcon
                              icon={faFileInvoiceDollar}
                              className='mr-2'
                            />

                            <span className='nk-menu-text'>
                              Payment Methods
                            </span>
                          </NavLink>
                        </li>
                        <li className='nk-menu-item sub-nav-item'>
                          <NavLink
                            activeClassName='active'
                            to={`/${props.match.params.company_id}/your-account`}
                            className='nk-menu-link '
                            id='your-account'
                          >
                            {/* <span className='nk-menu-icon icon-user-check icon-sm'></span> */}
                            <FontAwesomeIcon
                              icon={faUserCog}
                              className='mr-2'
                            />

                            <span className='nk-menu-text'>Your Account</span>
                          </NavLink>
                        </li>
                        <li className='nk-menu-item sub-nav-item'>
                          <NavLink
                            activeClassName='active'
                            id='company-settings'
                            to={`/${props.match.params.company_id}/company-settings`}
                            className='nk-menu-link '
                          >
                            {/* <span className='nk-menu-icon icon-company-settings icon-sm'></span> */}
                            <FontAwesomeIcon icon={faWrench} className='mr-2' />

                            <span className='text-8 text-md nk-menu-text'>
                              Company Settings
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                      {/* </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                {/* <li>
                  {props.selectedCompany.name.split("").map((char, index){
                    <li></li>
                  })}
                </li> */}
              </ul>
              {/*<!-- .nk-menu --> */}
            </div>

            <Dropdown>
              <Dropdown.Toggle
                variant='transparent'
                className='w-100 justify-content-start border-0 p-0'
                id='dropdown-basic'
              >
                <div className='nk-sidebar-footer w-100 pl-3'>
                  <ul className='nk-menu-footer p-0'>
                    <div className='user-avatar icon-md icon-status icon-status-info online'>
                      <img
                        src={
                          props.userDetails.icon
                            ? process.env.REACT_APP_PUBLIC_URL.replace('/v1/', '') +  props.userDetails.icon
                            : icon_user
                        }
                        alt='User'
                      />
                    </div>
                    <div className='user-info dropdown-indicator'>
                      <div className='user-name full-name'>
                        {props.userDetails.name}
                      </div>

                      <div className='user-name split-name'>
                        {props.userDetails.name && (
                          <span>
                            {NameSplitHelper.SidebarCompanyName(
                              props.userDetails.name
                            )}
                          </span>
                        )}
                      </div>
                      <div className='user-status text-left'>
                        {props.userDetails.role === 'user' ? 'Admin' : 'Member'}
                      </div>
                    </div>
                  </ul>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100 dropdown-menu-md side-logout'>
                <Dropdown.Item className='bg-transparent'>
                  <div className='dropdown-inner'>
                    <ul className='link-list p-0'>
                      <li>
                        <NavLink
                          // activeClassName='active'
                          to='/login'
                          onClick={() => {
                            Cookies.remove('user');
                            sessionStorage.removeItem('data');
                            delete AxiosInstance.defaults.headers.common[
                              'Authorization'
                            ];
                          }}
                          className='text-danger'
                        >
                          <em className='icon ni ni-signout'></em>
                          <span>Log out</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <!-- .nk-sidebar-content --> */}
        </div>
        {/* <!-- .nk-sidebar-body --> */}
      </div>
      <AddCompany
        addComp={addComp}
        setAddComp={setAddComp}
        getCompanies={getCompanies}
      />
    </div>
  );
};

export default SideNav;
