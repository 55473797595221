import React from 'react';
import ReactLoading from 'react-loading';
// import LoadingImage from '../../Assets/images/loader/Outinvoice2.gif'

const Loading = () => {
  // const className = props.className || '';

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        top: '50%',
      }}
    >
      <div
        style={{ position: 'absolute', marginTop: '20%', top: '50%' }}
      >
        {/* <img src={LoadingImage} alt="" height={100} width={100}/> */}
        <ReactLoading
          type={'bars'}
          color={'#3eb53e'}
          height={50}
          width={100}
        />
      </div>
    </div>
  );
};

export default Loading;
