import React from 'react';
import DashboardLayout from '../../Layouts/DashBoard.layout';
import SettingsLayout from '../../Layouts/Settings.layout';

const ContextLayout = React.createContext();

const layouts = {
  dashboard: DashboardLayout,
  settings: SettingsLayout,
};

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <ContextLayout.Provider
        value={{
          DashboardLayout: layouts['dashboard'],
          SettingsLayout: layouts['settings'],
        }}
      >
        {children}
      </ContextLayout.Provider>
    );
  }
}

export { Layout, ContextLayout };
